import React, { useEffect, useState } from 'react';
import './home.scss';
import { Button } from 'devextreme-react/button';
import { useExtractor } from '../../contexts/extractor';
import notify from 'devextreme/ui/notify';
import { LoadIndicator } from 'devextreme-react/load-indicator';

import backgroundImage from '../../images/back1.jpg';
import { Box, TextBox } from 'devextreme-react'
import logoImage from '../../images/nova-logo2.png';
import warningIcon from '../../images/warning.png';
export default function Home() {

  
  const [fileType, setFileType] = useState('');
  const { extractFiles } = useExtractor();
  const [loading, setLoading] = useState(false);


  const [replyMessage, setReplyMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([
    // Example old messages, replace with actual data if available
    { text: 'Hello!', sender: 'user' },
    { text: 'Hi! How can I help you?', sender: 'bot' }
  ]);
  
  const sendMessage = async (message: string) => {
    setLoading(true);
    const newChatMessage = { text: message, sender: 'user' };
    setChatMessages([...chatMessages, newChatMessage]);
    setReplyMessage('');
    const result = await extractFiles({
      fileName: message,
      fileType: fileType,
      files: [],
    });
    setLoading(false);
  
    if (result.isOk) {
      if (result.data && result.data.data && result.data.data.message) {
        const responseMessage = result.data.data.message;
        if (responseMessage) {
          const newBotChatMessage = { text: responseMessage, sender: 'bot' };
          setChatMessages(prevMessages => [...prevMessages, newBotChatMessage]);
        }
      } else {
        // Handle case when result data is null
        console.log("Result data is null");
      }
    } else {
      notify(result.message, 'error', 2000);
    }
  };
  
  return (
    <div className="home-container">
      <div className="left-side">
        {/* Content for left side, takes up 60% of the page */}
      </div>
      <div className="right-side">
        <div className="chat-window">
          <div className="chat-title-bar">
            <h6 className="title-chat"><b>Nova Virtual Assistant </b></h6>
          </div>
          <div className="default-panel">
            
            <div className="info">
            <div className="logo">
                <img src={logoImage} alt="Logo" />
              </div>
              <div className="welcome-message">
              <p className="welcome-text">Welcome to Nova Assist</p>
              <p>How can I help you today?</p>
            </div>
            <div className="cards">
              {/* Insert your cards with rounded borders here */}
              <div className="card">"In clinical practice, how do you differentiate between 'anterior' and 'ventral' anatomical orientations, and when would you use one term over the other?"</div>
              <div className="card">"What distinguishes 'prognosis' from 'diagnosis' in patient care, and how do healthcare providers utilize each term to inform treatment decisions?"</div>
            </div>
            <div className="cards">
              {/* Insert your cards with rounded borders here */}
              <div className="card-warn">
              <div className="warning-content">
                <img src={warningIcon} alt="Warning Icon" className="warning-icon" /> {/* Add warning icon */}
                <p>Your Contents may be saved in external Data servers. Don’t enter anything sensitive or you wouldn’t want reviewed or used by a 3rd party services.</p>
              </div>
            </div>
            
            </div>
            </div>
          </div>
          <div className="chat-messages">
  {chatMessages.slice().reverse().map((message, index) => (
    <div key={index} className={`chat-message ${message.sender === 'user' ? 'outgoing' : 'incoming'}`}>
      <p>{message.text}</p>
    </div>
  ))}
</div>
          <div className="reply-box">
            <TextBox
              value={replyMessage}
              onValueChanged={(e) => setReplyMessage(e.value)}
              placeholder="Type your message here..."
            />
            <Button
              icon="chevronright"
              stylingMode="contained"
              onClick={() => sendMessage(replyMessage)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
// export default Home;